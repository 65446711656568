import React from 'react';
import './Main.css';
import Item from './Item';

const Main = ({ article }) => {
    // console.log({article});
    return (
        <article className="main">
            <div className="intro">
                <h2>{article.heading}</h2>
                <p>{article.intro}</p>
            </div>
            <dl className="items">
                {Object.values(article.items).map((item) => (
                    <Item item={item} />
                ))}
            </dl>
        </article>
    );
}

export default Main;