import React from 'react';
import Brand from './Brand';
import './Footer.css';

const theYear = new Date().getFullYear();

function Footer() {
  return(
    <footer className="site-footer">
      <Brand />
      <hr />
      ©{theYear} U.S. Bank, All Rights Reserved
    </footer>
  );
}

export default Footer;