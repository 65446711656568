import React from 'react';

function HeaderContent(props) {
  return(
    <div className="header-content">
      <h1>{props.pageTitle}</h1>
      <h2>{props.pageSubtitle}</h2>
    </div>
  );
}

export default HeaderContent;