import React from 'react';
import Brand from './Brand';
import HeaderContent from './HeaderContent';
import './Header.css';

function Header() {
    return (
        <header className="header">
            <Brand title="Shield Design System" />
            <HeaderContent pageTitle="Shield Design System" pageSubtitle="Shield empowers journey and product teams to create world-class customer experience across the U.S. Bank digital ecosystem." />
        </header>
    );
}

export default Header;