import React from 'react';
import './App.css';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';

const articleArray = {
    heading: 'Principles',
    intro: 'The basic foundations apply to guidance, components and patterns for all our platforms.',
    items: [
        {
            icon: <svg aria-hidden="true" viewBox="0 0 32 32" width="25" height="25"><title>icon-dec-paper-airplane</title><path d="M30.72 3.31a1 1 0 00-1-.27l-28 8a1 1 0 00-.35 1.74l8.63 6.9V28a1 1 0 00.67.94A1 1 0 0011 29a1 1 0 00.78-.38L15.45 24l5.93 4.74A1 1 0 0022 29a.9.9 0 00.28 0 1 1 0 00.67-.64l8-24a1 1 0 00-.23-1.05zm-5.31 3L10.88 17.82l-6.74-5.39zM12 25.15v-3.87l1.89 1.51zm9.5 1.17l-9-7.22L28 6.86z"></path></svg>,
            iconType: 'blue',
            title: 'Actionable',
            description: 'We use clear, appropriately coded design and language to allow users of all abilities to do what they need to do.',
        },
        {
            icon: <svg aria-hidden="true" viewBox="0 0 32 32" width="25" height="25"><title>icon-dec-checkmark</title><path d="M16 1a15 15 0 1015 15A15 15 0 0016 1zm0 28a13 13 0 1113-13 13 13 0 01-13 13zm7.07-19l1.42 1.41L13.88 22a1 1 0 01-.71.29 1 1 0 01-.71-.29l-5-5 1.42-1.41 4.24 4.24z"></path></svg>,
            iconType: 'blue',
            title: 'Simple',
            description: 'We present the most essential information without losing opportunities for delight.',
        },
        {
            icon: <svg aria-hidden="true" viewBox="0 0 32 32" width="25" height="25"><title>icon-sys-chat</title><path d="M21 17a1 1 0 001-1V3a1 1 0 00-1-1H3a1 1 0 00-1 1v13a1 1 0 001 1h1.17L5 20.26a1 1 0 00.66.69A1.06 1.06 0 006 21a1 1 0 00.62-.22L11.39 17zm-10.58-1.78l-3.85 3-.67-2.48a1 1 0 00-1-.74H4V4h16v11h-9a1 1 0 00-.58.22zM30 12v13a1 1 0 01-1 1h-1.17L27 29.26a1 1 0 01-.66.69A1.06 1.06 0 0126 30a1 1 0 01-.62-.22L20.61 26H11a1 1 0 01-1-1v-5h2v4h9a1 1 0 01.62.22l3.85 3.05.67-2.53a1 1 0 011-.74H28V13h-3v-2h4a1 1 0 011 1z"></path></svg>,
            iconType: 'blue',
            title: 'Conversational',
            description: 'We communicate to our customers as we would in real life, using the tone that best suits each conext and message.',
        },
        {
            icon: <svg aria-hidden="true" viewBox="0 0 32 32" width="25" height="25"><title>icon-person</title><path d="M16 15a7 7 0 10-7-7 7 7 0 007 7zm0-12a5 5 0 11-5 5 5 5 0 015-5zm13 27h-2a11 11 0 00-22 0H3a13 13 0 0126 0z"></path></svg>,
            iconType: 'blue',
            title: 'Personalized',
            description: 'We offer customized content and opportunities, and make our experiences easy and relevant.',
        },
        {
            icon: <svg aria-hidden="true" viewBox="0 0 32 32" width="25" height="25"><title>icon-dec-group</title><path d="M11.5 15.5A4.5 4.5 0 1016 11a4.51 4.51 0 00-4.5 4.5zM16 13a2.5 2.5 0 11-2.5 2.5A2.5 2.5 0 0116 13zm7-2a4.5 4.5 0 10-4.5-4.5A4.51 4.51 0 0023 11zm0-7a2.5 2.5 0 11-2.5 2.5A2.5 2.5 0 0123 4zM9 11a4.5 4.5 0 10-4.5-4.5A4.51 4.51 0 009 11zm0-7a2.5 2.5 0 11-2.5 2.5A2.5 2.5 0 019 4zm14 25h-2a5 5 0 00-10 0H9a7 7 0 0114 0zM9 15a5 5 0 00-5 5H2a7 7 0 017-7zm21 5h-2a5 5 0 00-5-5v-2a7 7 0 017 7z"></path></svg>,
            iconType: 'blue',
            title: 'Trustworthy',
            description: 'We build trust via consistency, clarity and transparency. We design interfaces that function as customers expect them to, using words that treat them like respected partners.',
        },
    ],
}

const App = () => {
    return (
        <div className="App">
            <Header title="Shield Design System" />
            <Main article={articleArray} />
            <Footer />
        </div>
    );
}

export default App;
