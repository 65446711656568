import React from 'react';
import './Item.css';
import Icon from './Icon';

const Item = ({ item }) => {
    return (
        <div className="item">
            <Icon item={item} />
            <div className="content">
                <dt>{item.title}</dt>
                <dd>{item.description}</dd>
            </div>
        </div>
    );
}

export default Item;